import React, {useCallback, useEffect, useRef, useState} from "react";
import {Toast}            from "primereact/toast";
import {InputText}        from "primereact/inputtext";
import {Button}           from "primereact/button";
import {confirmDialog}    from "primereact/confirmdialog";
import {Dropdown}         from "primereact/dropdown";
import {Dialog}           from "primereact/dialog";
import {Checkbox}         from 'primereact/checkbox';
import {MultiSelect}      from "primereact/multiselect";
import api                from "../../services/api";
import Tabela             from "../../components/tabela";
import {parametrosPadrao} from "../../configs/constantes";
import {PrimeIcons}       from 'primereact/api';
import useMobile          from "../../utils/useMobile";
import {Obrigatorio}      from "../../styles/global";
import Notiflix           from "notiflix";
import {InputTextarea}    from "primereact/inputtextarea";
import {Editor}           from 'primereact/editor';
import debug              from "../../utils/debug";

const Categoria = () => {

    const classe = 'Categoria';
    const endpointAPI = 'cadastro/categoria';
    const tabela = 'categorias';
    const inicial = {
        categoria_id : 0,
        descricao    : '',
        nome         : '',
        rota         : '',
        status       : 1,
    };
    const aviso = useRef(null);
    const [item, setItem] = useState(inicial);
    const [html, setHtml] = useState('');
    const [tituloTela, setTituloTela] = useState('');
    const [exibeTela, setExibirTela] = useState(false);
    const [listar, setListar] = useState(false);
    const [listaModulo, setListaModulo] = useState(null);
    const [listaIcones, setListaIcones] = useState(null);
    const [funcionalidades, setFuncionalidades] = useState([]);
    const [funcionalidadesFiltradas, setFuncionalidadesFiltradas] = useState(null);
    const {isMobile} = useMobile();
    const [permissoesTela, setPermissoesTela] = useState(null);
    const [parametrosTela, setParametrosTela] = useState(parametrosPadrao);
    const [palavrasChave, setPalavrasChave] = useState([]);

    useEffect((e) => {
        new api().requisitar('get', `configuracao/tela/categoria/permissoes`).then((resposta) => {
            setPermissoesTela(resposta);

            const parametros = {
                pesquisarTabela  : true,
                artigo           : 'o',
                tabela           : tabela,
                nomeClasse       : classe,
                caminhoBreadcrumb: [{label: 'Cadastro'}, {label: 'Categorias'}],
                endpointApi      : endpointAPI,
                tabelaDados      : {
                    reordenar              : false,
                    multiplaSelecao        : false,
                    botaoAuditoriaRegistro : false,
                    botaoVisualizarRegistro: false,
                    botaoAdicionar         : resposta.cadastrar ?? false,
                    botaoExcluir           : resposta.excluir ?? false,
                    botaoEditarRegistro    : resposta.alterar ?? false,
                    botaoExcluirRegistro   : resposta.excluir ?? false,
                    colunas                : [{legenda: 'Nome', campo: 'nome', ordenavel: true}, {legenda: 'Rota da página', campo: 'rota', ordenavel: true}]
                },
                abrirFormulario  : aoAbrirFormulario,
                finalizarPesquisa: aoListar
            }
            setParametrosTela(parametros);
        }).catch((erro) => {
            throw {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
        });
    
        let temp = [];
        for (let icone in PrimeIcons) {
            temp.push({id: PrimeIcons[icone], valor: PrimeIcons[icone],})
        }
        setListaIcones(temp);

    }, []);

    const limparTela = () => {
        setItem(inicial);
        setHtml('');
        setExibirTela(false);
        setListar(true);
    };

    const aoListar = () => setListar(false);

    const aoSalvar = async () => {
        if (!item.nome) {
            throw {tipo: 'warn', nome: 'Atenção!!', mensagem: 'Informe o nome da categoria!'};
        }
        item.descricao = html;
        try {
            await new api().requisitar('post', `cadastro/categoria/atualizar-informacoes`, item).then((resposta) => {
                aviso.current.show({severity: (!resposta['sucesso'] ? 'error' : 'success'), summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
                limparTela();
            }).catch((erro) => {
                throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
            });

        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    };

    const excluir = async (id) => {
        await new api().requisitar('DELETE', `${endpointAPI}/${id}`).then((resposta) => {
            aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
            limparTela();
        }).catch((erro) => {
            debug(erro);
            aviso.current.show({
                                   severity: 'error',
                                   summary: 'Erro',
                                   detail: (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado',
                                   life: 3000
                               });
        });
    };

    const aoExcluirItem = (id) => {
        confirmDialog({
                          message: `Confirma exclusão da ${classe}?`,
                          header: 'Atenção',
                          icon: 'pi pi-exclamation-triangle',
                          acceptLabel: 'Sim',
                          rejectLabel: 'Não',
                          accept: () => {
                              excluir(id);
                          },
                      });
    };

    useEffect(() => {
        
    }, [parametrosTela]);

    const botoesAcaoTela = () => {
        return (<React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={(e) => setExibirTela(false)}/>
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={aoSalvar}/>
        </React.Fragment>);
    };

    const aoDigitar = (e, propriedade) => {
        const texto = (e.target && e.target.value) || '';
        let _item = {...item};
        _item[`${propriedade}`] = texto;
        if (propriedade === 'idmodulo' && texto !== '' && !_item.imovel_id) {
            _item.rotaportal = '/' + listaModulo.filter((m) => m.id === texto)[0].nome + '/';
        }
        setItem(_item);
    };

    const aoAbrirFormulario = async (registro) => {
        console.log(registro);
        if (registro.id) {
            setHtml('');
            setHtml(registro.descricao);
            Notiflix.Loading.circle({
                svgColor: "#FE962A"
            });
            new api().requisitar('get', `${endpointAPI}/buscar-informacoes-atualizacao?categoria_id=${registro.id}`).then((resposta) => {
                if(resposta['sucesso'] === true){
                    setItem(resposta['informacoesCategoria']);
                    setTituloTela(`Alterar ${classe} ${resposta['informacoesCategoria']['nome']}`);
                    setPalavrasChave(resposta['informacoesCategoria']['palavras_chave']);
                }
            }).then(()=>{
                setExibirTela(true);
            })
            .finally(()=>{
                Notiflix.Loading.remove();
            })
        } else {
            setHtml('');
            setItem(inicial);
            setTituloTela(`Cadastro de Nova Categoria`);
            Notiflix.Loading.remove();
            setExibirTela(true);
        }
    };

    const checkSlugCustomizavel = (e) => {
        const value = e.checked;
        setItem(prev=> ({
            ...prev,
            usar_slug_customizavel: value
        }));
    };

    const adicionarPalavraChave = () => {
        const palavraChaveInputElement = document.querySelector('#adicionar_palavra_chave');
        const newValue = palavraChaveInputElement.value;
        palavraChaveInputElement.value = '';
        if(newValue.length > 0){
            setPalavrasChave(prev=> [...prev, newValue]);
            setItem(prev=> ({
                ...prev,
                palavras_chave: [...prev.palavras_chave, newValue]
            }));
        }
    };

    return (
        <Tabela parametros={parametrosTela} listar={listar}>
            <Toast ref={aviso}></Toast>
            <Dialog className='fluid' header={tituloTela} style={{maxWidth: isMobile ? '450px' : '650px'}} footer={botoesAcaoTela} modal={true} visible={exibeTela} onHide={(e) => setExibirTela(false)}>
                <div className="grid formgrid">

                    <div className="field col-12 mt-3">
                        <label htmlFor="nome">Nome</label><br/>
                        <InputText className="w-full" placeholder={'Nome'} value={item.nome} autoComplete="none" id="nome" maxLength={255} onChange={(e) => aoDigitar(e, 'nome')} />
                    </div>

                    <div className="field col-12 mt-3">
                        <label htmlFor="nome">Rota da Página</label><br/>
                        <InputText className="w-full" placeholder={'Endereço da página, sem o domínio'} value={item.rota} autoComplete="none" id="rota" maxLength={255} onChange={(e) => aoDigitar(e, 'rota')} />
                    </div>

                    <div className="field col-12 mt-1">
                        <label htmlFor="texto">Descrição</label><br/>
                        <Editor className="w-full" id="descricao" value={html} onTextChange={(e) => setHtml(e.htmlValue)} style={{height: '320px'}} required/>
                    </div>

                    <div className="field col-12 mt-3">
                        <label htmlFor="meta_titulo">Meta Título</label><br/>
                        <InputText className="w-full" placeholder={'Meta Título'} value={item.meta_titulo} autoComplete="none" id="meta_titulo" maxLength={255} onChange={(e) => aoDigitar(e, 'meta_titulo')} />
                    </div>
                    
                    <div className="field col-12 mt-3">
                        <label htmlFor="meta_descricao">Meta Descrição</label><br/>
                        <InputText className="w-full" placeholder={'Meta Descrição'} value={item.meta_descricao} autoComplete="none" id="meta_descricao" maxLength={255} onChange={(e) => aoDigitar(e, 'meta_descricao')}/>
                    </div>
                </div>
            </Dialog>
        </Tabela>
    );

}

export default Categoria;