import React, {useCallback, useEffect, useRef, useState} from "react";
import {Toast} from "primereact/toast";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {confirmDialog} from "primereact/confirmdialog";
import {Dialog} from "primereact/dialog";

import api from "../../services/api";
import {Obrigatorio} from "../../styles/global";
import Tabela from "../../components/tabelaRedirecionamento";
import {parametrosPadrao} from "../../configs/constantes";
import {InputSwitch} from "primereact/inputswitch";
import debug from "../../utils/debug";
import {Chip} from "primereact/chip";

const Redirecionamento = () => {
    const classe = 'Redirecionamento';
    const tabela = 'redirecionamento';
    const endpointAPI = 'cadastro/redirecionamento';
    const inicial = {id: 0, origem: '', destino: '', status: 1, datacadastro: ''};
    const aviso = useRef(null);
    const [item, setItem] = useState(inicial);
    const [tituloTela, setTituloTela] = useState('');
    const [exibeTela, setExibirTela] = useState(false);
    const [listar, setListar] = useState(false);
    const [permissoesTela, setPermissoesTela] = useState(null);
    const [parametrosTela, setParametrosTela] = useState(parametrosPadrao);
    const [tipos, setTipos] = useState(null);

    const aoAbrirFormulario = async (registro) => {
        if (registro.id) {
            setItem(registro);
            setTituloTela(`Alterar ${classe}`);
        } else {
            setItem(inicial);
            setTituloTela(`Cadastro de ${classe}`);
        }
        setExibirTela(true);
    };

    const aoListar = () => setListar(false);

    const aoImportarCsv = async ({files}) => {
        if (!files) {
            aviso.current.show({
                severity: 'error',
                summary: 'Erro',
                detail: "Nenhum arquivo selecionado",
                life: 3000
            });
            return
        }

        const form = new FormData();
        form.append("csv", files[0])

        await new api().requisitar("POST", endpointAPI + "/csv", form)
            .then((resposta) => {
                debug(resposta)

                let logMessage = resposta.logs.map(log => `Linha: ${log[0]} - ${log[1]}`).join('<br>')

                if (logMessage) {
                    aviso.current.show({
                        severity: 'error',
                        summary: 'Erro',
                        detail: (<span dangerouslySetInnerHTML={{__html: logMessage}}></span>),
                        life: 3000,
                        allowHtml: true
                    });
                } else {
                    aviso.current.show({
                        severity: 'success',
                        summary: 'Confirmação',
                        detail: 'Registrado com sucesso',
                        life: 3000
                    });
                }

                limparTela()
            })
            .catch((erro) => {
                debug(erro);
                aviso.current.show({
                    severity: 'error',
                    summary: 'Erro',
                    detail: erro.response.data.errors[0],
                    life: 3000
                });
            });
    }

    const limparTela = () => {
        setItem(inicial);
        setExibirTela(false);
        setListar(true);
    };

    const excluir = async (id) => {
        await new api().requisitar('DELETE', `${endpointAPI}/${id}`).then((resposta) => {
            aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
            limparTela();
        }).catch((erro) => {
            debug(erro);
            aviso.current.show({
                severity: 'error',
                summary: 'Erro',
                detail: (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado',
                life: 3000
            });
        });
    };

    const aoExcluirItem = (id) => {
        confirmDialog({
            message: `Confirma exclusão do ${classe}?`,
            header: 'Atenção',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: () => {
                excluir(id);
            },
        });
    };

    const aoSalvar = async () => {
        try {

            if (!item.origem) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe uma url de origem!'};
            }

            if (!item.destino) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe uma url de destino!'};
            }

            await new api().requisitar((item.id > 0) ? 'put' : 'post', (item.id > 0 ? `${endpointAPI}/${item.id ?? ''}` : endpointAPI), {
                ...item,
            }).then((resposta) => {
                console.log("teste");
                if (!!resposta.mensagem) {
                    aviso.current.show({
                        severity: 'success',
                        summary: 'Confirmação',
                        detail: resposta.mensagem,
                        life: 3000
                    });
                    limparTela();
                }
            }).catch((erro) => {
                if (erro.response.data.errors) {
                    throw {
                        tipo: 'error',
                        titulo: 'Falha!!',
                        mensagem: erro.response.data.errors[0].replaceAll('\n', '<br>')
                    };
                }

                if (erro.response.data && typeof erro.response.data == 'string') {
                    throw {
                        tipo: 'error',
                        titulo: 'Falha!!',
                        mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'
                    };
                }

                throw {tipo: 'error', titulo: 'Falha!!', mensagem: 'Ocorreu um erro não tratado'};
            });

        } catch (erro) {
            aviso.current.show({
                severity: erro.tipo ?? 'error',
                summary: erro.titulo ?? 'Erro',
                detail: erro.mensagem ?? erro,
                life: 3000
            });
        }
    };

    const status = (data, obj) => {
        if (data.status == 1)
            return (
                <Chip style={{"backgroundColor": "transparent"}}
                      image={process.env.REACT_APP_URLAPI + "/uploads/svg/aprovado.svg"}/>
            );
        if (data.status != 1) {
            return (
                <Chip style={{"backgroundColor": "transparent"}}
                      image={process.env.REACT_APP_URLAPI + "/uploads/svg/reprovado.svg"}/>
            );
        }
    }

    const aoDigitar = (e, propriedade) => {
        let texto = (e.target && e.target.value) || '';
        let _item = {...item};
        _item[`${propriedade}`] = texto;
        setItem(_item);
    };

    const tplLocal = (data) => {
        if (tipos) {
            let tipo = tipos.filter(tipo => {
                return tipo.id === data.id_tipo;
            });

            if (!!tipo[0].valor) {
                return tipo[0].valor
            } else {
                return "Tipo não encontrado";
            }
        } else {
        }
    }

    useEffect(() => {
        new api().requisitar('get', `configuracao/tela/redirecionamento/permissoes`).then((resposta) => {
            setPermissoesTela(resposta);
            const parametros = {
                pesquisarTabela: true,
                nomeClasse: classe,
                tabela: tabela,
                artigo: 'o',
                caminhoBreadcrumb: [{label: 'Cadastro'}, {label: 'Redirecionamento'}],
                endpointApi: endpointAPI,
                tabelaDados: {
                    reordenar: false,
                    multiplaSelecao: true,
                    botaoAdicionar: resposta.cadastrar ?? false,
                    botaoExcluir: resposta.excluir ?? false,
                    botaoEditarRegistro: resposta.alterar ?? false,
                    botaoExcluirRegistro: resposta.excluir ?? false,
                    botaoAuditoriaRegistro: resposta.auditoria ?? false,
                    botaoVisualizarRegistro: resposta.visualizar ?? false,
                    colunas: [
                        {legenda: 'Origem', campo: 'origem', ordenavel: true},
                        {legenda: 'Destino', campo: 'destino', ordenavel: true},
                        {legenda: 'Status', campo: 'status', ordenavel: false, template: status},
                        {legenda: 'Data de Cadastro', campo: 'datacadastro', ordenavel: true},
                    ]
                },
                abrirFormulario: aoAbrirFormulario,
                finalizarPesquisa: aoListar,
                importarCsv: aoImportarCsv
            }
            setParametrosTela(parametros);
        }).catch((erro) => {
            throw {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
        });
    }, []);

    const botoesAcaoTela = () => {
        return (<React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text"
                    onClick={(e) => setExibirTela(false)}/>
            {item.id > 0 && permissoesTela.excluir &&
                <Button label="Excluir" icon="pi pi-trash" className="p-button-text"
                        onClick={(e) => aoExcluirItem(item.id)}/>}
            {((item.id > 0 && permissoesTela.alterar) || permissoesTela.cadastrar) &&
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={aoSalvar}/>}
        </React.Fragment>);
    };

    return (
        <Tabela parametros={parametrosTela} listar={listar}>
            <Toast ref={aviso}></Toast>
            <Dialog className='fluid' header={tituloTela} style={{width: '50%'}} footer={botoesAcaoTela} modal={true}
                    visible={exibeTela} onHide={(e) => setExibirTela(false)}>
                <div className="grid formgrid">
                    <div className="field col-12 mt-3">
                        <label htmlFor="titulo">Ativo? </label><br/>
                        <InputSwitch checked={item.status == 1 ? true : false}
                                     onChange={(e) => setItem({...item, status: e.value ? 1 : 2})}/>
                    </div>
                    <div className="field col-12 mt-3">
                        <label htmlFor="origem">Origem<Obrigatorio>*</Obrigatorio> </label><br/>
                        <div className="p-inputgroup flex-1">
                            <span className="p-inputgroup-addon text-lg">www.potencialimoveis.com.br</span>
                            <InputText autoComplete="none" id="origem" maxLength={255} placeholder="/home"
                                       value={item.origem} onChange={(e) => aoDigitar(e, 'origem')} required/>
                        </div>
                    </div>
                    <div className="field col-12 mt-3">
                        <label htmlFor="titulo">Destino<Obrigatorio>*</Obrigatorio> </label><br/>
                        <div className="p-inputgroup flex-1">
                            <span className="p-inputgroup-addon text-lg">www.potencialimoveis.com.br</span>
                            <InputText autoComplete="none" id="titulo" maxLength={255} placeholder="/contato"
                                       value={item.destino} onChange={(e) => aoDigitar(e, 'destino')} required/>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Tabela>
    );
}

export default Redirecionamento;
