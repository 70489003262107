import React, { useEffect, useState } from 'react'

const useMobile = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const onResize = () => {
            setIsMobile(window.innerWidth < 768);
        }

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [])

    return { isMobile }

}

export default useMobile;