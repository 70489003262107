import React, {useEffect, useRef, useState} from "react";
import {Toast}                              from "primereact/toast";
import {InputText}                          from "primereact/inputtext";
import {Button}                             from "primereact/button";
import {confirmDialog}                      from "primereact/confirmdialog";
import {Dialog}                             from "primereact/dialog";
import {Dropdown}                           from "primereact/dropdown";

import api                from "../../services/api";
import {Obrigatorio}      from "../../styles/global";
import Tabela             from "../../components/tabela";
import {parametrosPadrao} from "../../configs/constantes";
import {IdUsuarioLogado}  from "../../services/auth";
import debug              from "../../utils/debug";

const Usuario = () => {
    const classe = 'Usuário';
    const tabela = 'usuarios';
    const endpointAPI = 'seguranca/usuario';
    const inicial = {id: 0, nome: '', email: '', idperfil: 0, senha: '', confirmaSenha: '', fone: '', mudarSenha: false};
    const aviso = useRef(null);
    const [item, setItem] = useState(inicial);
    const [tituloTela, setTituloTela] = useState('');
    const [exibeTela, setExibirTela] = useState(false);
    const [listar, setListar] = useState(false);
    const [listaPerfil, setListaPerfil] = useState(false);
    const [permissoesTela, setPermissoesTela] = useState(null);
    const [parametrosTela, setParametrosTela] = useState(parametrosPadrao);

    const carregarCombos = async () => {
        await new api().requisitar('get', `/seguranca/perfil/combo`).then((resposta) => {
            setListaPerfil(resposta ?? []);
        }).catch((erro) => {
            debug(erro);
        });
    }

    const aoAbrirFormulario = async (registro) => {
        await carregarCombos();
        if (registro?.id) {
            await new api().requisitar('get', `${endpointAPI}/${registro.id}`).then((resposta) => {
                setItem(resposta);
                setTituloTela(`Alterar ${classe}`);
            }).catch((erro) => {
                debug(erro);
                aviso.current.show({severity: 'error', summary: 'Erro', detail: (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado', life: 3000});
            });
        } else {
            setItem(inicial);
            setTituloTela(`Cadastro de ${classe}`);
        }
        setExibirTela(true);
    };

    const aoListar = () => setListar(false);

    const limparTela = () => {
        setItem(inicial);
        setExibirTela(false);
        setListar(true);
    };

    const excluir = async (id) => {
        await new api().requisitar('DELETE', `${endpointAPI}/${id}`).then((resposta) => {
            aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
            limparTela();
        }).catch((erro) => {
            debug(erro);
            aviso.current.show({severity: 'error', summary: 'Erro', detail: (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado', life: 3000});
        });
    };

    const aoExcluirItem = (id) => {
        confirmDialog({
                          message    : `Confirma exclusão do ${classe}?`,
                          header     : 'Atenção',
                          icon       : 'pi pi-exclamation-triangle',
                          acceptLabel: 'Sim',
                          rejectLabel: 'Não',
                          accept     : () => {
                              excluir(id);
                          },
                      });
    };

    const aoSalvar = async () => {
        try {
            if (item.senha && item.senha !== item.confirmaSenha) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Senha não confere na confirmação!'};
            }
            if (!item.idperfil) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o perfil de acesso!'};
            }
            if (!item.email) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o email!'};
            }
            if (!item.nome) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o nome!'};
            }

            await new api().requisitar((item.id > 0) ? 'put' : 'post', (item.id > 0 ? `${endpointAPI}/${item.id ?? ''}` : endpointAPI), item).then((resposta) => {
                aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
                limparTela();
            }).catch((erro) => {
                throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
            });

        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    };

    const aoDigitar = (e, propriedade) => {
        let _item = {...item};
        const texto = (e.target && e.target.value) || '';
        _item[`${propriedade}`] = texto;
        setItem(_item);
    };

    useEffect((e) => {
        new api().requisitar('get', `configuracao/tela/usuario/permissoes`).then((resposta) => {
            setPermissoesTela(resposta);
            const parametros = {
                pesquisarTabela  : true,
                nomeClasse       : classe,
                tabela           : tabela,
                caminhoBreadcrumb: [{label: 'Segurança'}, {label: 'Usuários'}],
                endpointApi      : endpointAPI,
                tabelaDados      : {
                    reordenar              : false,
                    multiplaSelecao        : true,
                    botaoAdicionar         : resposta.cadastrar ?? false,
                    botaoExcluir           : resposta.excluir ?? false,
                    botaoEditarRegistro    : resposta.alterar ?? false,
                    botaoExcluirRegistro   : resposta.excluir ?? false,
                    botaoAuditoriaRegistro : resposta.auditoria ?? false,
                    botaoVisualizarRegistro: resposta.visualizar ?? false,
                    colunas                : [{legenda: 'Perfil', campo: 'perfil', ordenavel: true},
                                              {legenda: 'Nome', campo: 'nome', ordenavel: true}]
                },
                abrirFormulario  : aoAbrirFormulario,
                finalizarPesquisa: aoListar
            }
            setParametrosTela(parametros);
        }).catch((erro) => {
            throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
        });
    }, []);

    const botoesAcaoTela = () => {
        return (<React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={(e) => setExibirTela(false)}/>
            {item?.id > 1 && permissoesTela.excluir && <Button label="Excluir" icon="pi pi-trash" className="p-button-text" onClick={(e) => aoExcluirItem(item?.id)}/>}
            {((item?.id > 0 && permissoesTela.alterar) || permissoesTela.cadastrar) && <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={aoSalvar}/>}
        </React.Fragment>);
    };

    return (
        <Tabela parametros={parametrosTela} listar={listar}>
            <Toast ref={aviso}></Toast>
            <Dialog className='fluid' header={tituloTela} style={{maxWidth: '99%'}} footer={botoesAcaoTela} modal={true} visible={exibeTela} onHide={(e) => setExibirTela(false)}>
                <div className="grid formgrid">
                    <div className="field col-12 mt-3">
                        <label htmlFor="idperfil">Perfil<Obrigatorio>*</Obrigatorio> </label><br/>
                        <Dropdown className="w-full" autoComplete="none" id="idperfil" value={item?.idperfil} options={listaPerfil} onChange={(e) => aoDigitar(e, 'idperfil')} optionLabel="valor" optionValue="id" required/>
                    </div>
                    <div className="field col-12">
                        <label htmlFor="nome">Nome<Obrigatorio>*</Obrigatorio> </label><br/>
                        <InputText className="w-full" autoComplete="none" id="nome" maxLength={50} value={item.nome} onChange={(e) => aoDigitar(e, 'nome')} required/>
                    </div>
                    <div className="field col-12">
                        <label htmlFor="email">E-mail<Obrigatorio>*</Obrigatorio> </label><br/>
                        <InputText className="w-full" autoComplete="none" id="email" maxLength={50} value={item.email} onChange={(e) => aoDigitar(e, 'email')} required/>
                    </div>
                    {!item?.id || item?.id === IdUsuarioLogado() &&
                     <>
                         <div className="field col-6">
                             <label htmlFor="senha">Senha<Obrigatorio>*</Obrigatorio></label><br/>
                             <InputText className="w-full" autoComplete="off" type="password" id="senha" maxLength={50} value={item.senha} onChange={(e) => aoDigitar(e, 'senha')} required/>
                         </div>
                         <div className="field col-6">
                             <label htmlFor="csenha">Confirmação de senha<Obrigatorio>*</Obrigatorio></label><br/>
                             <InputText className="w-full" autoComplete="off" type="password" id="csenha" maxLength={50} value={item.confirmaSenha} onChange={(e) => aoDigitar(e, 'confirmaSenha')} required/>
                         </div>
                     </>
                    }
                </div>
            </Dialog>
        </Tabela>
    );
}

export default Usuario;
