import {ConfirmDialog, confirmDialog}       from "primereact/confirmdialog";
import {classNames}                         from 'primereact/utils';
import React, {useEffect, useRef, useState} from 'react';
import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import {useEventListener}                   from 'primereact/hooks';
import AppMenu                              from '../components/AppMenu';
import AppTopbar                            from '../components/AppTopbar';
import api                                  from "../services/api";

import {Autenticado, LiberarToken, NomeUsuarioLogado} from "../services/auth";
import Context                     from "../services/context";
import {Sidebar}                   from "primereact/sidebar";
import {AutoComplete}              from "primereact/autocomplete";
import debug                       from "../utils/debug";


const SistemaLayout = ({children, telaRestrita = true, ...rest}) => {
    let navegacao = useNavigate();
    let location = useLocation();
    let menuClick = false;
    let topbarItemClick = false;
    const cPesquisa = useRef(null);
    const [itensMenu, setItensMenu] = useState([]);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [atualizarMenu, setAtualizarMenu] = useState(false);
    const [profileMode, setProfileMode] = useState('inline');
    const [activeTopbarItem, setActiveTopbarItem] = useState(null);
    const [menuMode, setMenuMode] = useState('static');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('filled');
    const [ripple, setRipple] = useState(false);
    const [pesquisarMenu, setPesquisarMenu] = useState(false);
    const [rotateMenuButton, setRotateMenuButton] = useState(false);
    const [darkMenu, setDarkMenu] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [menuLista, setMenuLista] = useState([]);
    const [menuListaFiltro, setMenuListaFiltro] = useState([]);
    const [menuSelecionado, setMenuSelecionado] = useState(null);

    let containerClassName = classNames('layout-wrapper', {
        'menu-layout-static'         : menuMode !== 'overlay',
        'menu-layout-overlay'        : menuMode === 'overlay',
        'layout-menu-overlay-active' : overlayMenuActive,
        'menu-layout-slim'           : menuMode === 'slim',
        'menu-layout-horizontal'     : menuMode === 'horizontal',
        'layout-menu-static-inactive': staticMenuDesktopInactive,
        'layout-menu-static-active'  : staticMenuMobileActive,
        'p-input-filled'             : inputStyle === 'filled',
        'p-ripple-disabled'          : !ripple
    });

    const [bindKeyDown, unbindKeyDown] = useEventListener({
                                                              type    : 'keydown',
                                                              listener: (e) => {
                                                                  if (e.code === 'KeyM' && e.ctrlKey) {
                                                                      setPesquisarMenu(true);
                                                                      //cPesquisa.current.focus();
                                                                  }
                                                              }
                                                          });

    useEffect(() => {
        if (Autenticado()) {
            carregarMenu();
        } else if (telaRestrita) {
            LiberarToken();
            navegacao('/');
        }
    }, []);

    useEffect(() => {
        if (atualizarMenu) {
            carregarMenu();
        }
    }, [atualizarMenu]);

    useEffect(() => {
        bindKeyDown();
        return () => {
            unbindKeyDown();
        };
    }, [bindKeyDown, unbindKeyDown]);


    const carregarMenu = async () => {
        new api().requisitar('get', `/configuracao/menu/lista`).then(async (resposta) => {
            setMenuLista(resposta);
        }).catch((erro) => {
            debug(erro);
        });

        new api().requisitar('get', `/configuracao/menu/carregar`).then(async (resposta) => {
            let itens = resposta;
            itens.push({
                           label: 'Sair',
                           icon : 'pi pi-power-off'
                       });

            setItensMenu(itens);
            setAtualizarMenu(false);
        }).catch((erro) => {
            debug(erro);
        });
    };

    const aoSair = () => {
        confirmDialog({
                          message        : 'Deseja realmente fazer logoff do sistema?',
                          header         : 'Atenção',
                          icon           : 'pi ',
                          acceptLabel    : 'Sim',
                          acceptClassName: 'p-button-pi',
                          rejectLabel    : 'Não',
                          rejectClassName: 'p-button-pi p-button-outlined',
                          accept         : () => {
                              LiberarToken();
                              navegacao('/');
                          },
                      });
    };

    const onDocumentClick = () => {
        if (!topbarItemClick) {
            setActiveTopbarItem(null);
            setTopbarMenuActive(false);
        }

        menuClick = false;
        topbarItemClick = false;
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setRotateMenuButton(false);
        setStaticMenuMobileActive(false);
    };

    const filtrarMenu = (event) => {
        // Timeout to emulate a network connection
        setTimeout(() => {
            let _itensFiltrados;

            if (!event.query.trim().length) {
                _itensFiltrados = [...menuLista];
            } else {
                _itensFiltrados = menuLista.filter((sMenu) => {
                    return sMenu.titulo.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setMenuListaFiltro(_itensFiltrados);
        }, 250);
    }

    const aoPressionar = (event) => {
        if (event.key === 'Enter' && menuSelecionado) {
            setMenuSelecionado(null);
            setMenuListaFiltro(null);
            setPesquisarMenu(false);
            navegacao(menuSelecionado.url);
        }
    }

    const isDesktop = () => {
        return window.innerWidth > 1024;
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setRotateMenuButton((prev) => !prev);
        setTopbarMenuActive(false);

        if (menuMode === 'overlay') {
            setOverlayMenuActive((prevOverlayMenuActive) => !prevOverlayMenuActive);
        } else {
            if (isDesktop()) {
                setStaticMenuDesktopInactive((prevStaticMenuDesktopInactive) => !prevStaticMenuDesktopInactive);
            } else {
                setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive);
            }
        }

        event.preventDefault();
    };

    const onTopbarMenuButtonClick = (event) => {
        topbarItemClick = true;
        setTopbarMenuActive((prevTopbarMenuActive) => !prevTopbarMenuActive);
        hideOverlayMenu();
        event.preventDefault();
    };

    const onMenuClick = (event) => {
        if (event.target.innerText.toUpperCase() === 'SAIR') {
            aoSair();
        }
        if (event && typeof event.addClass === 'function') {
            event.addClass('router-link-active');
        }
        menuClick = true;
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isMenuVisible = () => {
        if (isDesktop()) {
            if (menuMode === 'static') {
                return !staticMenuDesktopInactive;
            } else if (menuMode === 'overlay') {
                return overlayMenuActive;
            } else {
                return true;
            }
        } else {
            if (staticMenuMobileActive) {
                return true;
            } else if (menuMode === 'overlay') {
                return overlayMenuActive;
            } else {
                return true;
            }
        }
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const onMenuitemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();
        }

        if (!event.item.items && (isSlim() || isHorizontal())) {
            setMenuActive(false);
        }
    };

    const onRootMenuitemClick = () => {
        setMenuActive((prevMenuActive) => !prevMenuActive);
    };

    let menuClassName = classNames('layout-menu-container', {
        'layout-menu-dark'              : darkMenu,
        'layout-menu-container-inactive': !isMenuVisible()
    });

    return (
        <Context.Provider value={[atualizarMenu, setAtualizarMenu]}>
            <React.Fragment>
                <ConfirmDialog/>
                {telaRestrita && !Autenticado() &&
                 <Navigate to="/" state={{from: location}} replace/>
                }

                {!telaRestrita &&
                 <>
                     {children}
                 </>
                }

                {telaRestrita && Autenticado() &&
                 <div>
                     <Sidebar position={'top'} visible={pesquisarMenu} onHide={() => setPesquisarMenu(false)}>
                         <AutoComplete placeholder={'Digite o nome da tela para acesso direto'} autoFocus field={'titulo'} forceSelection id={'cpPesquisa'} inputClassName={'w-full'} className={'w-full'} suggestions={menuListaFiltro} value={menuSelecionado} completeMethod={filtrarMenu} onChange={(e) => setMenuSelecionado(e.value)} onKeyPress={aoPressionar}/>
                     </Sidebar>
                     <div className={containerClassName} onClick={onDocumentClick}>
                         <div className="layout-wrapper-content">
                             <AppTopbar
                                 topbarMenuActive={topbarMenuActive}
                                 profileMode={profileMode}
                                 horizontal={true}
                                 activeTopbarItem={activeTopbarItem}
                                 rotateMenuButton={rotateMenuButton}
                                 onMenuButtonClick={onMenuButtonClick}
                                 onTopbarMenuButtonClick={onTopbarMenuButtonClick}
                                 onTopbarItemClick={(e) => debug('Clicked')}
                                 username={NomeUsuarioLogado()}
                             />
                             <div className={menuClassName} onClick={onMenuClick}>
                                 <div className="menu-scroll-content">
                                     <AppMenu model={itensMenu} menuMode={menuMode} active={menuActive}
                                              onMenuitemClick={onMenuitemClick}
                                              onRootMenuitemClick={onRootMenuitemClick}></AppMenu>
                                 </div>
                             </div>
                             <div className="layout-main">
                                 {children}
                             </div>
                         </div>
                     </div>
                 </div>
                }
            </React.Fragment>
        </Context.Provider>
    );
};


export default SistemaLayout;
