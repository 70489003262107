import React from 'react';

import {Container} from './styles';
import Login       from "../pages/seguranca/login";

const LoginLayout = () => (
	<Container>
		<Login/>
	</Container>
);


export default LoginLayout;
