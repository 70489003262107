import { closestCenter, DndContext, KeyboardSensor, PointerSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { Button } from "primereact/button";
import { useInterval } from 'primereact/hooks';
import { Toast } from "primereact/toast";
import React, { useCallback, useEffect, useRef, useState } from "react";
import api from "../../services/api";

import { Container } from "../../styles/global";
import debug from "../../utils/debug";
import SortableItem from './components/SortableItem';


const Dashboard = () => {

    const endpointAPI = 'dashboard';
    const aviso = useRef(null);
    const [configurar, setConfigurar] = useState(false);
    const [permissoesTela, setPermissoesTela] = useState(null);
    const [indicadores, setIndicadores] = useState([]);
    const sensors = useSensors(
        useSensor(TouchSensor, { activationConstraint: { distance: 15 } }),
        useSensor(PointerSensor, { activationConstraint: { distance: 15 } }),
        useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
    );

    function handleDragEnd(event) {
        const { active, over } = event;
        if (active.id !== over.id) {
            const oldIndex = indicadores.findIndex(indicador => indicador.id === active.id);
            const newIndex = indicadores.findIndex(indicador => indicador.id === over.id);
            var _indicadores = arrayMove(indicadores, oldIndex, newIndex);
            for (let i = 0; i < _indicadores.length; i++) {
                _indicadores[i].ordem = i;
            }
            setIndicadores(_indicadores);
            return _indicadores;
        }
    }

    const aoRedimensionar = (tamanho, indicador) => {
        let _indicadores = [...indicadores];
        let mudou = false;
        for (let i = 0; i < _indicadores.length; i++) {
            if (_indicadores[i].nome === indicador && _indicadores[i].tamanho !== tamanho) {
                _indicadores[i].tamanho = tamanho;
                mudou = true;
                break;
            }
        }
        if (mudou) {
            setIndicadores(_indicadores);
        }
    }

    const aoDigitar = (e, propriedade) => {
        let _indicadores = [...indicadores];
        _indicadores = _indicadores.map((indicador) => {
            if (indicador.nome === propriedade) {
                indicador.visivel = e.value;
            }
            return indicador;
        });
        setIndicadores(_indicadores);
    };

    const forcarAtualizacaoGeral = async () => {
        let _indicadores = [...indicadores];
        for (let i = 0; i < _indicadores.length; i++) {
            _indicadores[i].atualizado = 0;
            _indicadores[i].atualizando = false;
        }
        setIndicadores(_indicadores);
        setConfigurar(!configurar);
    }

    const aoSalvarConfiguracao = async () => {
        if (configurar) {
            await new api().requisitar('PUT', `${endpointAPI}/configurar`, { indicadores: indicadores }).then(async (resposta) => {
                aviso.current.show({ severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000 });
                await forcarAtualizacaoGeral();
            }).catch((erro) => {
                debug(erro);
                aviso.current.show({
                    severity: 'error',
                    summary: 'Erro',
                    detail: (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado',
                    life: 3000
                });
            });
        } else {
            await forcarAtualizacaoGeral();
        }
    }

    const aoAtualizar = useCallback((nome) => {
        if (!configurar) {
            let _indicadores = [...indicadores];
            for (let i = 0; i < _indicadores.length; i++) {
                if (_indicadores[i].nome === nome && _indicadores[i].visivel && !_indicadores[i].atualizando &&
                    (_indicadores[i].atualizado === 0 || (_indicadores[i].atualizacao > 0 && (new Date()).getTime() > _indicadores[i].atualizado + (_indicadores[i].atualizacao * 1000)))) {
                    _indicadores[i].atualizando = true;
                    _indicadores[i].atualizado = (new Date()).getTime();
                    setIndicadores(_indicadores);
                    new api().requisitar('get', `${endpointAPI}/atualizar/${nome}`).then((resposta) => {
                        _indicadores[i].atualizando = false;
                        _indicadores[i].valor = resposta?.dados;
                        if (resposta?.dados[0]?.atualizado) {
                            _indicadores[i].atualizado = resposta?.dados[0]?.atualizado;
                        }
                        setIndicadores(_indicadores);
                    });
                    break;
                }
            }
        }
    }, [configurar, indicadores]);

    const atualizaIndicadores = useCallback(() => {
        for (let i = 0; i < indicadores.length; i++) {
            aoAtualizar(indicadores[i].nome);
        }
    }, [aoAtualizar, indicadores]);

    useInterval(() => atualizaIndicadores(), 1000, !configurar);

    useEffect(() => {
        atualizaIndicadores();
    }, [atualizaIndicadores, indicadores]);

    useEffect((e) => {
        new api().requisitar('get', `configuracao/tela/dashboard/permissoes`).then((resposta) => {
            setPermissoesTela(resposta);
        }).catch((erro) => {
            throw { tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado' };
        });
    }, []);

    return (
        <Container>
            <Toast ref={aviso}></Toast>
            {permissoesTela?.configurar &&
                <div className="text-right m-2">
                    {configurar && <Button icon="pi pi-cog" className={'mr-2'} label={'Salvar configuração'} severity="success" aria-label="Configurar" onClick={aoSalvarConfiguracao} />}
                    <Button icon="pi pi-cog" severity="info" aria-label="Configurar" onClick={(e) => setConfigurar(!configurar)} />
                </div>
            }
            {indicadores.length > 0 &&
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                    <SortableContext items={indicadores}>
                        <div className="grid formgrid">
                            {indicadores?.map(indicador => <SortableItem key={indicador.id} indicador={indicador}
                                id={indicador.id} configurar={configurar}
                                callbackOnResize={(t, i) => aoRedimensionar(t, i)}
                                callbackOnType={(e, p) => aoDigitar(e, p)} />)}
                        </div>
                    </SortableContext>
                </DndContext>
            }
            
        </Container>
    );
}

export default Dashboard;
