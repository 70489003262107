import {classNames} from 'primereact/utils';
import {Dropdown} from "primereact/dropdown";

import Logo from "../assets/LogoLogin.svg";
import useMobile from '../utils/useMobile';

const AppTopbar = (props) => {
    const {isMobile} = useMobile();

    const menuButtonClassName = classNames('p-link', {
        'menu-button-rotate': props.rotateMenuButton,
        'left-auto mr-5 right-0': isMobile
    });

    const legendaTitulo = 'Potencial Imóveis';

    return (
        <div className="topbar clearfix" style={{backgroundColor: "#e6e6e6", pointerEvents: 'visible'}}>
            <div className="topbar-left flex justify-content-left md:w-17rem md:bg-black-alpha-10 w-5">
                <button className="p-link flex align-items-center">
                    <img src={Logo} alt={legendaTitulo} title={legendaTitulo} rel={"noreferrer"} className='block'
                         style={{height: '100%', objectFit: 'contain'}}/>
                </button>
            </div>

            <div className="topbar-right align-items-center flex justify-content-end md:w-auto w-auto">
                <button className={menuButtonClassName} id="menu-button" onClick={props.onMenuButtonClick}>
                    <i className="pi pi-angle-left"></i>
                </button>
                <div className="text-right hidden md:block">
                    <label className="title align-items-center flex justify-content-end md:w-auto w-5">
                        <p className="text-lg text-overflow-ellipsis mr-4">
                            Olá <span className="font-medium">{props.username}</span> !
                        </p>
                    </label>
                </div>
            </div>
            <div className="block md:hidden w-full">
                <label
                    className="title flex w-full justify-content-center align-items-center bg-gray-400 p-2 text-white-alpha-90">
                    Olá {props.username} !
                </label>
            </div>
        </div>
    );
};

export default AppTopbar;
