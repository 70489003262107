import 'primeflex/primeflex.css';
import 'primeflex/primeflex.min.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';

import React               from 'react';
import {Route, Routes}     from "react-router-dom";
import {addLocale, locale} from 'primereact/api';

import LoginLayout        from "./layouts/LoginLayout";
import SistemaLayout      from "./layouts/SistemaLayout";
import Funcionalidade     from "./pages/configuracao/funcionalidade";
import Idioma             from "./pages/configuracao/idioma";
import Menu               from "./pages/configuracao/menu";
import Modulo             from "./pages/configuracao/modulo";
import Tela               from "./pages/configuracao/tela";
import Dashboard          from "./pages/dashboard/index";
import Categoria          from "./pages/cadastro/categoria";
import Banner             from "./pages/cadastro/banner";
import Esquecisenha       from "./pages/seguranca/esquecisenha";
import NovaSenha          from "./pages/seguranca/novasenha";
import Perfil             from "./pages/seguranca/perfil";
import Usuario            from "./pages/seguranca/usuario";
import EmailTemplate      from "./pages/configuracao/emailtemplate";

import Context     from "./services/context";
import {portugues} from "./configs/utils";
import './styles/estilos.css';
import './styles/tema-pi.css';
import Imovel from './pages/cadastro/imovel';
import Condominio from './pages/cadastro/condominio';
import LinkYoutube from "./pages/cadastro/linkyoutube";
import Redirecionamento from "./pages/cadastro/redirecionamento";

export default function App() {
    addLocale('pt-BR', portugues);
    locale('pt-BR');

    return (
        <Context.Provider value={[false, () => {
        }]}>
                <Routes>
                    <Route path="/" element={<LoginLayout/>}/>
                    <Route path="/resetar-senha" element={<SistemaLayout telaRestrita={false}><NovaSenha/></SistemaLayout>}/>
                    <Route path="/esqueci-senha" element={<SistemaLayout telaRestrita={false}><Esquecisenha/></SistemaLayout>}/>
                    <Route path="/seguranca/perfil" element={<SistemaLayout><Perfil/></SistemaLayout>}/>
                    <Route path="/seguranca/usuario" element={<SistemaLayout><Usuario/></SistemaLayout>}/>
                    <Route path="/configuracao/modulo" element={<SistemaLayout><Modulo/></SistemaLayout>}/>
                    <Route path="/configuracao/tela" element={<SistemaLayout><Tela/></SistemaLayout>}/>
                    <Route path="/configuracao/funcionalidade" element={<SistemaLayout><Funcionalidade/></SistemaLayout>}/>
                    <Route path="/configuracao/menu" element={<SistemaLayout><Menu/></SistemaLayout>}/>
                    <Route path="/configuracao/idioma" element={<SistemaLayout><Idioma/></SistemaLayout>}/>
                    <Route path="/dashboard" element={<SistemaLayout><Dashboard/></SistemaLayout>}/>
                    <Route path="/cadastro/banner" element={<SistemaLayout><Banner/></SistemaLayout>}/>
                    <Route path="/cadastro/link-youtube" element={<SistemaLayout><LinkYoutube/></SistemaLayout>}/>
                    <Route path="/cadastro/imovel" element={<SistemaLayout><Imovel/></SistemaLayout>}/>
                    <Route path="/cadastro/condominio" element={<SistemaLayout><Condominio/></SistemaLayout>}/>
                    <Route path="/cadastro/categoria" element={<SistemaLayout><Categoria/></SistemaLayout>}/>
                    <Route path="/cadastro/redirecionamento" element={<SistemaLayout><Redirecionamento/></SistemaLayout>}/>
                    <Route path="/configuracao/email-template" element={<SistemaLayout><EmailTemplate/></SistemaLayout>}/>
                </Routes>
        </Context.Provider>
    );
}
