import React, {useRef, useState}  from "react";
import {Toast}                    from "primereact/toast";
import {Button}                   from "primereact/button";
import {InputText}                from "primereact/inputtext";
import {useLocation, useNavigate} from "react-router-dom";
import {Dialog}                   from "primereact/dialog";
import axios                      from 'axios';

import {Container, Tela} from "../../styles/styles";
import Logo              from "../../assets/LogoLogin.png";

import * as S from './styles';

const NovaSenha = () => {
	const search = useLocation().search;
	const inicial = {
		token:       new URLSearchParams(search).get('token'),
		senha:       '',
		repitaSenha: ''
	};
	const [item, setItem] = useState(inicial);
	const aviso = useRef(null);
	const [alerta, setAlerta] = useState(false);
	const [mensagem, setMensagem] = useState('');
	const [loading, setLoading] = useState(false);
	const legendaTitulo = 'Potencial Imóveis';
	const navegacao = useNavigate();
	
	
	
	const aoEnviar = async () => {
		try {
			
			if (!item.senha) {
				throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe a senha!'};
			}
			
			if (!item.repitaSenha) {
				throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Repita a senha!'};
			}
			
			if (item.repitaSenha !== item.senha) {
				throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Senhas não conferem'};
			}
			
			setLoading(true);
			const resp = await axios.put(process.env.REACT_APP_URLAPI + 'seguranca/usuario/nova-senha', item);
			if (resp.data) {
				setLoading(false);
				setMensagem(resp.data.mensagem);
				setAlerta(true);
			} else {
				setLoading(false);
				throw  {tipo: 'error', titulo: 'Falha!!', mensagem: resp.data ?? 'Ocorreu um erro não tratado'};
			}
			
		} catch (erro) {
			setLoading(false);
			const msg = erro.mensagem ?? (erro.response.data ?? 'Ocorreu um erro não tratado');
			aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: msg ?? erro, life: 3000});
		}
	};
	
	const aoFechar = () => {
		setAlerta(false);
		navegacao('/');
	}
	
	const aoEnter = (e) => {
		if (e.key === 'Enter' && item.senha && item.repitaSenha) {
			aoEnviar(e);
		}
	};
	
	const aoDigitar = (e, propriedade) => {
		const texto = (e.target && e.target.value) || '';
		let _item = {...item};
		_item[`${propriedade}`] = texto;
		setItem(_item);
	};
	
	const botao = (name) => {
		return (
			<div>
				<Button label="OK" icon="pi pi-check" onClick={aoFechar} autoFocus/>
			</div>
		);
	}
	
	return (<Container>
		<Toast ref={aviso}></Toast>
		<Dialog onHide={aoFechar} visible={alerta} footer={botao} header="Confirmação">
			<label>{mensagem}</label>
		</Dialog>
		<Tela>
			<div className="grid">
				<div className="field col-12" style={{textAlign: 'center'}}>
					<div style={{padding: '0 10% 0 10%'}}>
						<a href="https://potencialimoveis.com.br/" target="__blank">
							<S.Logo src={Logo} alt={legendaTitulo} title={legendaTitulo} rel={"noreferrer"}/>
						</a>
					</div>
				</div>
				<div className="field col-12">
					<S.Formulario>
						<InputText placeholder={'Nova senha'} className={'campo w-full'} autoComplete={'off'} type="password" id="senha" maxLength={50} value={item.senha} onChange={(e) => aoDigitar(e, 'senha')} onKeyDown={aoEnter} disabled={loading} required/>
					</S.Formulario>
				</div>
				<div className="field col-12">
					<S.Formulario>
						<InputText placeholder={'Confirmação da senha'} className={'campo w-full'} autoComplete={'off'} type="password" id="repitaSenha" maxLength={50} value={item.repitaSenha} onChange={(e) => aoDigitar(e, 'repitaSenha')} onKeyDown={aoEnter} disabled={loading} required/>
					</S.Formulario>
				</div>
				<div className="field col-12" style={{textAlign: 'center'}}>
					<S.ButtonAcessar>
						<Button label='Confirmar' onClick={aoEnviar} className={'botao'} disabled={loading}/>
					</S.ButtonAcessar>
				</div>
			</div>
		</Tela>
	</Container>);
}


export default NovaSenha;
