import jwtDecode from "jwt-decode";
export const TOKEN_KEY = process.env['REACT_APP_TOKEN_KEY'];
export const REFRESH_TOKEN_KEY = process.env['REACT_APP_REFRESH_TOKEN_KEY'];

export const Autenticado = () => ((localStorage.getItem(TOKEN_KEY) !== null) && (jwtDecode(localStorage.getItem(TOKEN_KEY)).exp > parseInt(new Date().getTime() / 1000)));
export const Token = () => localStorage.getItem(TOKEN_KEY);
export const RefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);
export const RegistrarToken = (token, refreshtoken) => {
	localStorage.setItem(TOKEN_KEY, token);
	localStorage.setItem(REFRESH_TOKEN_KEY, refreshtoken);
};
export const LiberarToken = () => {
	localStorage.removeItem(TOKEN_KEY);
	localStorage.removeItem(REFRESH_TOKEN_KEY);
};
export const UsuarioMaster = () => jwtDecode(localStorage.getItem(TOKEN_KEY)).idperfil === process.env['REACT_APP_IDMASTER'];

/** @deprecated */
export const SetarContexto = (idcampanha) => {
	localStorage.setItem(process.env['REACT_APP_CONTEXT_KEY'], idcampanha);
}

/** @deprecated */
export const ResetarContexto = () => {
	localStorage.removeItem(process.env['REACT_APP_CONTEXT_KEY']);
}

export const Contexto = () => localStorage.getItem(process.env['REACT_APP_CONTEXT_KEY']);

export const IdUsuarioLogado = () => jwtDecode(localStorage.getItem(TOKEN_KEY)).jti;

export const NomeUsuarioLogado = () => jwtDecode(localStorage.getItem(TOKEN_KEY)).nome;
