import React, {useEffect, useRef, useState} from "react";
import {Toast}                              from "primereact/toast";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {confirmDialog} from "primereact/confirmdialog";
import {Dropdown} from "primereact/dropdown";
import {Dialog} from "primereact/dialog";
import {Checkbox} from 'primereact/checkbox';
import {MultiSelect}      from "primereact/multiselect";
import api from "../../services/api";
import Tabela from "../../components/tabela";
import {parametrosPadrao} from "../../configs/constantes";
import {PrimeIcons} from 'primereact/api';
import useMobile          from "../../utils/useMobile";
import {Obrigatorio} from "../../styles/global";
import Notiflix from "notiflix";

const Condominio = () => {

    const classe = 'Condominio';
    const endpointAPI = 'cadastro/condominio';
    const tabela = 'condominios';
    const inicial = {
        imovel_id                   : 0,
        meta_descricao              : '',
        meta_titulo                 : '',
        palavras_chave              : [],
        usar_slug_customizavel     : false,
        slug_customizavel          : '',
    };
    const aviso = useRef(null);
    const [item, setItem] = useState(inicial);
    const [tituloTela, setTituloTela] = useState('');
    const [exibeTela, setExibirTela] = useState(false);
    const [listar, setListar] = useState(false);
    const [listaModulo, setListaModulo] = useState(null);
    const [listaIcones, setListaIcones] = useState(null);
    const [funcionalidades, setFuncionalidades] = useState([]);
    const [funcionalidadesFiltradas, setFuncionalidadesFiltradas] = useState(null);
    const {isMobile} = useMobile();
    const [permissoesTela, setPermissoesTela] = useState(null);
    const [parametrosTela, setParametrosTela] = useState(parametrosPadrao);
    const [palavrasChave, setPalavrasChave] = useState([]);

    useEffect((e) => {

        const parametros = {
            pesquisarTabela  : true,
            artigo           : 'o',
            tabela           : tabela,
            nomeClasse       : classe,
            caminhoBreadcrumb: [{label: 'Cadastro'}, {label: 'Condomínios'}],
            endpointApi      : endpointAPI,
            tabelaDados      : {
                reordenar              : false,
                multiplaSelecao        : false,
                botaoAdicionar         : false,
                botaoExcluir           : false,
                botaoEditarRegistro    : true,
                botaoExcluirRegistro   : false,
                botaoAuditoriaRegistro : false,
                botaoVisualizarRegistro: false,
                colunas                : [{legenda: 'Nome', campo: 'nome', ordenavel: true}]
            },
            abrirFormulario  : aoAbrirFormulario,
            finalizarPesquisa: aoListar
        }
        setParametrosTela(parametros);
    
        let temp = [];
        for (let icone in PrimeIcons) {
            temp.push({id: PrimeIcons[icone], valor: PrimeIcons[icone],})
        }
        setListaIcones(temp);

    }, []);

    const limparTela = () => {
        setItem(inicial);
        setExibirTela(false);
        setListar(true);
    };

    const aoListar = () => setListar(false);

    const aoSalvar = async () => {
        try {
            await new api().requisitar('post', `cadastro/condominio/atualizar-informacoes`, item).then((resposta) => {
                aviso.current.show({severity: (!resposta['sucesso'] ? 'error' : 'success'), summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
                
                limparTela();
            }).catch((erro) => {
                throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
            });

        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    };

    useEffect(() => {
        
    }, [parametrosTela]);

    const botoesAcaoTela = () => {
        return (<React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={(e) => setExibirTela(false)}/>
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={aoSalvar}/>
        </React.Fragment>);
    };

    const aoDigitar = (e, propriedade) => {
        const texto = (e.target && e.target.value) || '';
        let _item = {...item};
        _item[`${propriedade}`] = texto;
        if (propriedade === 'idmodulo' && texto !== '' && !_item.imovel_id) {
            _item.rotaportal = '/' + listaModulo.filter((m) => m.id === texto)[0].nome + '/';
        }
        setItem(_item);
    };

    const aoAbrirFormulario = async (registro) => {
        console.log(registro);
        if (registro.id) {
            Notiflix.Loading.circle({
                svgColor: "#FE962A"
            });
            new api().requisitar('get', `${endpointAPI}/buscar-informacoes-atualizacao?condominio_id=${registro.id}`).then((resposta) => {
                if(resposta['sucesso'] === true){
                    setItem(resposta['informacoesCondominio']);
                    setTituloTela(`Alterar ${classe} ${resposta['informacoesCondominio']['nome']}`);
                    setPalavrasChave(resposta['informacoesCondominio']['palavras_chave']);
                }
            }).then(()=>{
                setExibirTela(true);
            })
            .finally(()=>{
                Notiflix.Loading.remove();
            })
        }
    };

    const checkSlugCustomizavel = (e) => {
        const value = e.checked;
        setItem(prev=> ({
            ...prev,
            usar_slug_customizavel: value
        }));
    };

    const adicionarPalavraChave = () => {
        const palavraChaveInputElement = document.querySelector('#adicionar_palavra_chave');
        const newValue = palavraChaveInputElement.value;
        palavraChaveInputElement.value = '';
        if(newValue.length > 0){
            setPalavrasChave(prev=> [...prev, newValue]);
            setItem(prev=> ({
                ...prev,
                palavras_chave: [...prev.palavras_chave, newValue]
            }));
        }
    };

    return (
        <Tabela parametros={parametrosTela} listar={listar}>
            <Toast ref={aviso}></Toast>
            <Dialog className='fluid' header={tituloTela} style={{maxWidth: isMobile ? '450px' : '650px'}} footer={botoesAcaoTela} modal={true} visible={exibeTela} onHide={(e) => setExibirTela(false)}>
                <div className="grid formgrid">
                    <div className="field col-12 mt-3">
                        <Checkbox inputId="usar_slug_customizavel" checked={item.usar_slug_customizavel} onChange={e => checkSlugCustomizavel(e)}/>
                        <label htmlFor="usar_slug_customizavel" className="ml-2" style={{display: 'initial'}}>Utilizar URL customizável</label>
                    </div>
                    <div className="field col-12 mt-3" style={{display: item.usar_slug_customizavel ? 'block' : 'none'}}>
                        <label htmlFor="slug_customizavel">URL Customizável</label><br/>
                        <InputText className="w-full" autoComplete="none" id="slug_customizavel" value={item.slug_customizavel} placeholder={'Slug Customizável'} maxLength={255} onChange={(e) => aoDigitar(e, 'slug_customizavel')} />
                    </div>
                    <div className="field col-12 mt-3">
                        <label htmlFor="meta_titulo">Meta Título</label><br/>
                        <InputText className="w-full" placeholder={'Meta Título'} value={item.meta_titulo} autoComplete="none" id="meta_titulo" maxLength={255} onChange={(e) => aoDigitar(e, 'meta_titulo')} />
                    </div>
                    <div className="field col-12 mt-3">
                        <label htmlFor="meta_descricao">Meta Descrição</label><br/>
                        <InputText className="w-full" placeholder={'Meta Descrição'} value={item.meta_descricao} autoComplete="none" id="meta_descricao" maxLength={255} onChange={(e) => aoDigitar(e, 'meta_descricao')}/>
                    </div>
                    <div className="field col-12 mt-3">
						<label htmlFor="palavras_chave">Palavras Chave</label><br/>
						<MultiSelect id="palavras_chave" className="w-full" value={item.palavras_chave} options={palavrasChave} onChange={(e) => aoDigitar(e, 'palavras_chave')} display="chip"/>
                        <InputText className="mt-3" placeholder={'Adicionar Palavra Chave'} autoComplete="none" id="adicionar_palavra_chave" maxLength={255} />
                        <Button icon="pi pi-check" className="p-button-text" onClick={(e) => adicionarPalavraChave()}/>
                    </div>
                
                </div>
            </Dialog>
        </Tabela>
    );

}

export default Condominio;