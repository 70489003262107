import {Button}                             from "primereact/button";
import {Dialog}                             from "primereact/dialog";
import {Editor}                             from "primereact/editor";
import {InputSwitch}                        from "primereact/inputswitch";
import {InputText}                          from "primereact/inputtext";
import {Toast}                              from "primereact/toast";
import React, {useEffect, useRef, useState} from "react";
import Tabela                               from "../../components/tabela";
import {parametrosPadrao}                   from "../../configs/constantes";

import api           from "../../services/api";
import {Obrigatorio} from "../../styles/global";

const EmailTemplate = () => {

    const classe = 'Templates de E-mail';
    const tabela = 'emails_templates';
    const endpointAPI = 'configuracao/email-template';
    const inicial = {id: 0, nome: '', titulo: '', texto: '', assunto: '', template: '', sms: '', sms_texto: '', tags: {}};
    const aviso = useRef(null);
    const [item, setItem] = useState(inicial);
    const [tituloTela, setTituloTela] = useState('');
    const [exibeTela, setExibirTela] = useState(false);
    const [listar, setListar] = useState(false);
    const [html, setHtml] = useState('');
    const [permissoesTela, setPermissoesTela] = useState(null);
    const [parametrosTela, setParametrosTela] = useState(parametrosPadrao);
    const [sms, setSms] = useState(false);


    const aoAbrirFormulario = async (registro) => {
        setHtml('');
        setSms(registro.sms);
        setHtml(registro.texto);
        setItem({...registro});
        setTituloTela(`Alterar ${classe}`);
        setExibirTela(true);
    };

    const aoListar = () => setListar(false);

    const limparTela = () => {
        setItem(inicial);
        setHtml('');
        setExibirTela(false);
        setListar(true);
    };

    const aoSalvar = async () => {
        try {

            if (!item.titulo) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o título do e-mail!'};
            }

            if (!item.assunto) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o assunto do e-mail!'};
            }

            if (!html) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o texto do e-mail!'};
            }

            item.texto = html;

             if (!item.sms) {
                item.sms_de = "";
            }

            if (item.sms && !item.sms_texto) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o texto do SMS!'};
            } else if (!item.sms_texto) {
                item.sms_texto = "";
            }

            await new api().requisitar((item.id > 0) ? 'put' : 'post', (item.id > 0 ? `${endpointAPI}/${item.id ?? ''}` : endpointAPI), item).then((resposta) => {
                aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
                limparTela();
            }).catch((erro) => {
                throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
            });

        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    };

    const aoDigitar = (e, propriedade) => {
        let _item = {...item};
        const texto = (e.target && e.target.value) || '';

        if (propriedade === 'sms') {
            _item[`${propriedade}`] = e.value == false ? 0 : true;
            setSms(e.value);
        } else {
            const texto = (e.target && e.target.value) || '';
            _item[`${propriedade}`] = texto;
        }

        setItem(_item);
    };

    useEffect((e) => {
        new api().requisitar('get', `configuracao/tela/emailtemplate/permissoes`).then((resposta) => {
            setPermissoesTela(resposta);
            const parametros = {
                pesquisarTabela  : true,
                nomeClasse       : classe,
                tabela           : tabela,
                artigo           : 'o',
                caminhoBreadcrumb: [{label: 'Configurações'}, {label: 'Templates de E-mail'}],
                endpointApi      : endpointAPI,
                tabelaDados      : {
                    atualizar              : true,
                    reordenar              : false,
                    multiplaSelecao        : false,
                    botaoAdicionar         : resposta.cadastrar ?? false,
                    botaoExcluir           : resposta.excluir ?? false,
                    botaoEditarRegistro    : resposta.alterar ?? false,
                    botaoExcluirRegistro   : resposta.excluir ?? false,
                    botaoAuditoriaRegistro : resposta.auditoria ?? false,
                    botaoVisualizarRegistro: resposta.visualizar ?? false,
                    colunas                : [
                        {legenda: 'Nome', campo: 'nome', ordenavel: true},
                        {legenda: 'Template', campo: 'template', ordenavel: true}
                    ]
                },
                abrirFormulario  : aoAbrirFormulario,
                finalizarPesquisa: aoListar
            }
            setParametrosTela(parametros);
        }).catch((erro) => {
            throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
        });
    }, []);

    const botoesAcaoTela = () => {
        return (<React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={(e) => setExibirTela(false)}/>
            {((item.id > 0 && permissoesTela.alterar) || permissoesTela.cadastrar) && <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={aoSalvar}/>}
        </React.Fragment>);
    };

    return (
        <Tabela parametros={parametrosTela} listar={listar}>
            <Toast ref={aviso}></Toast>
            <Dialog className='fluid' header={tituloTela} style={{width: '50%'}} footer={botoesAcaoTela} modal={true} visible={exibeTela} onHide={(e) => setExibirTela(false)}>
                <div className="grid formgrid">
              
                    <div className="field col-6">
                        <label htmlFor="nome">Nome</label><br/>
                        <InputText className="w-full" autoComplete="none" id="nome" maxLength={255} value={item.nome} onChange={(e) => aoDigitar(e, 'nome')} disabled required/>
                    </div>
                    <div className="field col-6">
                        <label htmlFor="template">Template</label><br/>
                        <InputText className="w-full" autoComplete="none" id="template" maxLength={255} value={item.template} onChange={(e) => aoDigitar(e, 'template')} disabled required/>
                    </div>
                    <div className="field col-12">
                        <label htmlFor="assunto">Assunto do E-mail<Obrigatorio>*</Obrigatorio> </label><br/>
                        <InputText className="w-full" autoComplete="none" id="assunto" maxLength={255} value={item.assunto ?? ''} onChange={(e) => aoDigitar(e, 'assunto')} required/>
                    </div>
                    <div className="field col-12">
                        <label htmlFor="titulo">Título do E-mail<Obrigatorio>*</Obrigatorio> </label><br/>
                        <InputText className="w-full" autoComplete="none" id="titulo" maxLength={255} value={item.titulo ?? ''} onChange={(e) => aoDigitar(e, 'titulo')} required/>
                    </div>
                    <div className="field col-12 mt-1">
                        <label htmlFor="texto">Texto do E-mail<Obrigatorio>*</Obrigatorio> </label><br/>
                        <label htmlFor="texto">
                            Dados para adicionar no e-mail:
                            {Object.entries(item?.tags || {}).map(item => {
                                const key = item[0]; //chave
                                const value = item[1]; //valor
                                return (
                                    <>
                                        <br/>
                                        <span><strong>{key} : </strong>{value}</span>
                                    </>
                                )
                            })}
                        </label><br/>
                        <Editor className="w-full" id="resposta" value={html} onTextChange={(e) => setHtml(e.htmlValue)} style={{height: '320px'}} required/>
                    </div>
                    <div className="field col-12 mt-1">
                        <label htmlFor="sms">Enviar SMS?</label><br/>
                        <InputSwitch id="sms" checked={item.sms} onChange={(e) => aoDigitar(e, 'sms')}/>
                    </div>
                    {sms &&
                     <>
                         <React.Fragment>
                             <div className="field col-12">
                                 <label htmlFor="sms_de">De SMS</label><br/>
                                 <InputText className="w-full" autoComplete="none" id="sms_de" maxLength={255} value={item.sms_de ?? ''} onChange={(e) => aoDigitar(e, 'sms_de')} required/>
                             </div>
                             <div className="field col-12">
                                 <label htmlFor="sms_texto">Texto SMS<Obrigatorio>*</Obrigatorio> </label><br/>
                                 <InputText className="w-full" autoComplete="none" id="sms_texto" maxLength={255} value={item.sms_texto ?? ''} onChange={(e) => aoDigitar(e, 'sms_texto')} required/>
                             </div>
                         </React.Fragment>
                     </>
                    }
                </div>
            </Dialog>
        </Tabela>
    );
}

export default EmailTemplate;
