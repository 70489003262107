import React, {useCallback, useEffect, useRef, useState} from "react";
import {Toast}                              from "primereact/toast";
import {InputText}                          from "primereact/inputtext";
import {Button}                             from "primereact/button";
import {confirmDialog}                      from "primereact/confirmdialog";
import {Dropdown}                           from "primereact/dropdown";
import {Dialog}                             from "primereact/dialog";
import {InputTextarea}                      from "primereact/inputtextarea";

import api                from "../../services/api";
import {Obrigatorio}      from "../../styles/global";
import Tabela             from "../../components/tabela";
import {parametrosPadrao} from "../../configs/constantes";
import GaleriaImagem      from "../../components/galeriaImagem";
import {InputSwitch}      from "primereact/inputswitch";
import debug              from "../../utils/debug";
import {FileUpload} from "primereact/fileupload";
import {Calendar} from "primereact/calendar";
import EnviarImagem from "../../components/enviarImagem";
import {Image} from "primereact/image";

const Banner = () => {
    const classe = 'Banner';
    const tabela = 'banners';
    const endpointAPI = 'cadastro/banner';
    const inicial = {id: 0, titulo: '', texto: '', imagem: '', imagemmobile: '', url: '', validade: '', id_tipo: 0, status: 0, datacadastro: ''};
    const aviso = useRef(null);
    const [item, setItem] = useState(inicial);
    const [tituloTela, setTituloTela] = useState('');
    const [exibeTela, setExibirTela] = useState(false);
    const [listar, setListar] = useState(false);
    const [permissoesTela, setPermissoesTela] = useState(null);
    const [parametrosTela, setParametrosTela] = useState(parametrosPadrao);
    const [tipos, setTipos] = useState(null);
    const [dimensao, setDimensao] = useState({desktop: null, mobile: null});

    const aoAbrirFormulario = async (registro) => {
        if (registro.id) {
            setItem({...registro, validade: new Date(registro.validade.replace(" ", "T"))});
            setTituloTela(`Alterar ${classe}`);
        } else {
            setItem(inicial);
            setTituloTela(`Cadastro de ${classe}`);
        }
        setExibirTela(true);
    };

    const aoListar = () => setListar(false);

    const limparTela = () => {
        setItem(inicial);
        setExibirTela(false);
        setListar(true);
    };

    const excluir = async (id) => {
        await new api().requisitar('DELETE', `${endpointAPI}/${id}`).then((resposta) => {
            aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
            limparTela();
        }).catch((erro) => {
            debug(erro);
            aviso.current.show({
                                   severity: 'error',
                                   summary : 'Erro',
                                   detail  : (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado',
                                   life    : 3000
                               });
        });
    };

    const aoExcluirItem = (id) => {
        confirmDialog({
                          message    : `Confirma exclusão do ${classe}?`,
                          header     : 'Atenção',
                          icon       : 'pi pi-exclamation-triangle',
                          acceptLabel: 'Sim',
                          rejectLabel: 'Não',
                          accept     : () => {
                              excluir(id);
                          },
                      });
    };

    const aoSalvar = async () => {
        try {

            if (!item.titulo) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe um título!'};
            }

            if (!item.validade) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe a data de validade!'};
            }

            if (!item.texto) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe um texto!'};
            }

            await new api().requisitar((item.id > 0) ? 'put' : 'post', (item.id > 0 ? `${endpointAPI}/${item.id ?? ''}` : endpointAPI), {...item, validade: item.validade.getFullYear() + '-' + (item.validade.getMonth()+1) + '-' + item.validade.getDate() + ' ' + item.validade.getHours() + ':' + item.validade.getMinutes()  + ':' + item.validade.getSeconds()}).then((resposta) => {
                aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
                limparTela();
            }).catch((erro) => {
                throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
            });

        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    };

    const aoDigitar = (e, propriedade) => {
        let texto = (e.target && e.target.value) || '';
        let _item = {...item};
        _item[`${propriedade}`] = texto;
        setItem(_item);
    };

    const tplLocal = (data) => {
        if (tipos) {
            let tipo = tipos.filter(tipo => {
                    return tipo.id === data.id_tipo;
                });

            if (!!tipo[0].valor) {
                return tipo[0].valor
            } else {
                return "Tipo não encontrado";
            }
        } else {
        }
    }
    useEffect(() => {
        if (!!tipos) {
            new api().requisitar('get', `configuracao/tela/banner/permissoes`).then((resposta) => {
                setPermissoesTela(resposta);
                const parametros = {
                    pesquisarTabela: true,
                    nomeClasse: classe,
                    tabela: tabela,
                    artigo: 'a',
                    caminhoBreadcrumb: [{label: 'Cadastro'}, {label: 'Banners'}],
                    endpointApi: endpointAPI,
                    tabelaDados: {
                        reordenar: false,
                        multiplaSelecao: true,
                        botaoAdicionar: resposta.cadastrar ?? false,
                        botaoExcluir: resposta.excluir ?? false,
                        botaoEditarRegistro: resposta.alterar ?? false,
                        botaoExcluirRegistro: resposta.excluir ?? false,
                        botaoAuditoriaRegistro: resposta.auditoria ?? false,
                        botaoVisualizarRegistro: resposta.visualizar ?? false,
                        colunas: [
                            {legenda: 'Título', campo: 'titulo', ordenavel: true},
                            {
                                legenda: 'Url',
                                campo: 'url',
                                ordenavel: true,
                                template: (data) => <a href={data.url} target="_blank" rel="noreferrer">{data.url}</a>
                            },
                            {legenda: 'Validade', campo: 'validade', ordenavel: false},
                            {legenda: 'Local', campo: 'id_tipo', ordenavel: true, template: (data) => (<p>{tipos.filter(tipo => tipo.id == data.id_tipo)[0].valor}</p>)}
                        ]
                    },
                    abrirFormulario: aoAbrirFormulario,
                    finalizarPesquisa: aoListar
                }
                setParametrosTela(parametros);
            }).catch((erro) => {
                throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
            });
        }
    }, [tipos]);

    useEffect((e) => {
        if (!tipos) {
            new api().requisitar('get', 'cadastro/tipo-banner/combo').then((resposta) => {
                setTipos(resposta);
            }).catch((erro) => {
                throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
            });
        }
    }, []);

    useEffect(() => {
        if (!!item.id_tipo) {
            let tipo = tipos.filter(tipo => {
                return tipo.id === item.id_tipo
            })
            setDimensao({desktop: tipo[0].dimensao, mobile: tipo[0].dimensaoMobile});
        } else {
            setDimensao({desktop: null, mobile: null});
        }
    }, [item]);

    const botoesAcaoTela = () => {
        return (<React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={(e) => setExibirTela(false)}/>
            {item.id > 0 && permissoesTela.excluir && <Button label="Excluir" icon="pi pi-trash" className="p-button-text" onClick={(e) => aoExcluirItem(item.id)}/>}
            {((item.id > 0 && permissoesTela.alterar) || permissoesTela.cadastrar) && <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={aoSalvar}/>}
        </React.Fragment>);
    };

    const aoUpload = async (e) => {
        try {

            let formulario = new FormData;
            formulario.append('imagem', e.files[0]);

            await new api().requisitar('post', process.env.REACT_APP_URLAPI + endpointAPI + '/upload', formulario).then((resposta) => {
                aviso.current.show({severity: 'success', summary: 'Confirmação', detail: "Imagem enviada com sucesso!", life: 3000});
                setItem({...item ,[e.options.props.name]: resposta[0]});
                return resposta;
            }).catch((erro) => {
                throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
            });
        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    }

    const templateUpload = (e) => {
        return (
            <div className={"flex flex-row"}>
                <Image alt={URL.createObjectURL(e)} role="presentation" src={URL.createObjectURL(e)} height={160} preview/>
            </div>
        );
    }

    const templateEmpty = (e) => {
        if (!e.url) {
            return (
                <p className="m-0">Arraste o arquivo para enviar.</p>
            );
        } else {
            return (
                <div className={"flex flex-row"}>
                    <Image alt={e.url} role="presentation" src={e.url} height={160} preview/>
                </div>
            )
        }
    }

    return (
        <Tabela parametros={parametrosTela} listar={listar}>
            <Toast ref={aviso}></Toast>
            <Dialog className='fluid' header={tituloTela} style={{width: '50%'}} footer={botoesAcaoTela} modal={true} visible={exibeTela} onHide={(e) => setExibirTela(false)}>
                <div className="grid formgrid">
                    <div className="field col-12 mt-3">
                        <label htmlFor="titulo">Título<Obrigatorio>*</Obrigatorio> </label><br/>
                        <InputText className="w-full" autoComplete="none" id="titulo" maxLength={255} value={item.titulo} onChange={(e) => aoDigitar(e, 'titulo')} required/>
                    </div>
                    <div className="field col-12">
                        <label htmlFor="url">Url</label><br/>
                        <InputText className="w-full" autoComplete="none" id="url" maxLength={255} value={item.url} onChange={(e) => aoDigitar(e, 'url')}/>
                    </div>
                    <div className="field col-6">
                        <label htmlFor="tipo">Local do banner</label><br/>
                        <Dropdown value={item.id_tipo} onChange={(e) => aoDigitar(e, 'id_tipo')} options={tipos}
                                  placeholder="Selecione o local" className="w-full" optionLabel="valor" optionValue="id" />
                    </div>
                    <div className="field col-6">
                        <label htmlFor="validade">Validade</label><br/>
                        <Calendar className="w-full" showTime={true} showSeconds={true} value={item.validade} onChange={(e) => aoDigitar(e, 'validade')} showIcon required/>
                    </div>
                    <div className="field col-12 mt-1">
                        <label htmlFor="texto">Texto</label><br/>
                        <InputTextarea rows={5} className="w-full" autoComplete="none" id="texto" value={item.texto} onChange={(e) => aoDigitar(e, 'texto')} autoResize/>
                    </div>
                    <div className="field col-12 mt-1">
                        <label htmlFor={"imagem"}>Banner Desktop {dimensao.desktop && "(" + dimensao.desktop + ")"}</label>
                        <FileUpload name="imagem" auto={true} url={item.imagem} itemTemplate={templateUpload} chooseLabel={"Enviar arquivo"} customUpload uploadHandler={aoUpload} accept="image/*" maxFileSize={1000000} emptyTemplate={templateEmpty} onChange={(e) => aoDigitar(e, 'imagem')} />
                    </div>
                    <div className="field col-12 mt-1">
                        <label htmlFor="imagemmobile">Banner Mobile {dimensao.mobile && "(" + dimensao.mobile + ")"}</label><br/>
                        <FileUpload name="imagemmobile" auto={true} url={item.imagemmobile} itemTemplate={templateUpload} chooseLabel={"Enviar arquivo"} customUpload uploadHandler={aoUpload} accept="image/*" maxFileSize={1000000} emptyTemplate={templateEmpty} onChange={(e) => aoDigitar(e, 'imagemmobile')} />
                    </div>
                </div>
            </Dialog>
        </Tabela>
    );
}

export default Banner;
