import React, {useCallback, useEffect, useRef, useState} from "react";
import {Toast} from "primereact/toast";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {confirmDialog} from "primereact/confirmdialog";
import {Dropdown} from "primereact/dropdown";
import {Dialog} from "primereact/dialog";
import {InputTextarea} from "primereact/inputtextarea";

import api from "../../services/api";
import {Obrigatorio} from "../../styles/global";
import Tabela from "../../components/tabela";
import {parametrosPadrao} from "../../configs/constantes";
import GaleriaImagem from "../../components/galeriaImagem";
import {InputSwitch} from "primereact/inputswitch";
import debug from "../../utils/debug";
import {FileUpload} from "primereact/fileupload";
import {Calendar} from "primereact/calendar";
import EnviarImagem from "../../components/enviarImagem";
import {Image} from "primereact/image";
import {Toolbar} from "primereact/toolbar";
import {Chip} from "primereact/chip";
import Notiflix from "notiflix";
import {Message} from "primereact/message";

const LinkYoutube = () => {
    const classe = 'LinkYoutube';
    const tabela = 'links_youtube';
    const endpointAPI = 'cadastro/link-youtube';
    const inicial = {id: 0, titulo: '', url: '', linkexterno: '', ordem: 0, status: 0, datacadastro: '', aparecesite: false};
    const aviso = useRef(null);
    const [item, setItem] = useState(inicial);
    const [tituloTela, setTituloTela] = useState('');
    const [exibeTela, setExibirTela] = useState(false);
    const [listar, setListar] = useState(false);
    const [permissoesTela, setPermissoesTela] = useState(null);
    const [parametrosTela, setParametrosTela] = useState(parametrosPadrao);

    const aoAbrirFormulario = async (registro) => {
        Notiflix.Loading.circle({
            svgColor: "#FE962A"
        });
        if (registro.id) {
            new api().requisitar('get', `${endpointAPI}/${registro.id}`).then(async (resposta) => {
                setItem(resposta);
                setTituloTela(`Alterar Link do Youtube`);
            }).catch((erro) => {
                debug(erro);
            }).finally(() => {
                Notiflix.Loading.remove();
                setExibirTela(true);
            })
        } else {
            setItem(inicial);
            setTituloTela(`Cadastro de Link do Youtube`);
            Notiflix.Loading.remove();
            setExibirTela(true);
        }
    };

    const aoListar = () => setListar(false);

    const limparTela = () => {
        setItem(inicial);
        setExibirTela(false);
        setListar(true);
    };

    const excluir = async (id) => {
        await new api().requisitar('DELETE', `${endpointAPI}/${id}`).then((resposta) => {
            aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
            limparTela();
        }).catch((erro) => {
            debug(erro);
            aviso.current.show({
                severity: 'error',
                summary: 'Erro',
                detail: (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado',
                life: 3000
            });
        });
    };

    const aoExcluirItem = (id) => {
        confirmDialog({
            message: `Confirma exclusão do ${classe}?`,
            header: 'Atenção',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: () => {
                excluir(id);
            },
        });
    };

    const aoSalvar = async () => {
        try {

            if (!item.titulo) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe um título!'};
            }

            if (!item.url) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe um texto!'};
            }

            await new api().requisitar((item.id > 0) ? 'put' : 'post', (item.id > 0 ? `${endpointAPI}/${item.id ?? ''}` : endpointAPI), item).then((resposta) => {
                aviso.current.show({
                    severity: 'success',
                    summary: 'Confirmação',
                    detail: resposta.mensagem,
                    life: 3000
                });
                limparTela();
            }).catch((erro) => {
                throw {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
            });

        } catch (erro) {
            aviso.current.show({
                severity: erro.tipo ?? 'error',
                summary: erro.titulo ?? 'Erro',
                detail: erro.mensagem ?? erro,
                life: 3000
            });
        }
    };

    const aoDigitar = (e, propriedade) => {
        let texto = (e.target && e.target.value) || '';
        let _item = {...item};
        _item[`${propriedade}`] = texto;
        setItem(_item);
    };

    const footer = () => {
        return (
            <div className="flex flex-column border-none w-full">
                <div className="card flex flex-wrap gap-2 p-toolbar border-none mb-1">
                    <Chip style={{"backgroundColor": "#f8f9fa"}} label="Em exibição no site"
                          image={process.env.REACT_APP_URLAPI + "/uploads/svg/aprovado.svg"}/>
                    <Chip style={{"backgroundColor": "#f8f9fa"}} label="Não exibido no site"
                          image={process.env.REACT_APP_URLAPI + "/uploads/svg/neutro.svg"}/>
                    <Chip style={{"backgroundColor": "#f8f9fa"}} label="Desativado"
                          image={process.env.REACT_APP_URLAPI + "/uploads/svg/reprovado.svg"}/>
                </div>
                <Message className="w-full" severity="warn" text="Apenas os últimos 4 vídeos cadastrados são exibidos no site."/>
            </div>
        );
    }

    const status = (data, obj) => {
        if (data.status == 1 && data.aparecesite)
            return (
                <Chip style={{"backgroundColor": "transparent"}}
                      image={process.env.REACT_APP_URLAPI + "/uploads/svg/aprovado.svg"}/>
            );
        if (data.status == 1) {
            return (
                <Chip style={{"backgroundColor": "transparent"}}
                      image={process.env.REACT_APP_URLAPI + "/uploads/svg/neutro.svg"}/>
            );
        } else if (data.status == 2) {
            return (
                <Chip style={{"backgroundColor": "transparent"}}
                      image={process.env.REACT_APP_URLAPI + "/uploads/svg/reprovado.svg"}/>
            );
        }
    }


    useEffect(() => {
        new api().requisitar('get', `configuracao/tela/youtube-link/permissoes`).then((resposta) => {
            setPermissoesTela(resposta);
            const parametros = {
                pesquisarTabela: true,
                nomeClasse: "Links",
                tabela: tabela,
                artigo: 'o',
                caminhoBreadcrumb: [{label: 'Cadastro'}, {label: 'Links do youtube'}],
                endpointApi: endpointAPI,
                tabelaDados: {
                    reordenar: true,
                    multiplaSelecao: true,
                    botaoAdicionar: resposta.cadastrar ?? false,
                    botaoExcluir: resposta.excluir ?? false,
                    botaoEditarRegistro: resposta.alterar ?? false,
                    botaoExcluirRegistro: resposta.excluir ?? false,
                    botaoAuditoriaRegistro: resposta.auditoria ?? false,
                    botaoVisualizarRegistro: resposta.visualizar ?? false,
                    colunas: [
                        {legenda: 'Título', campo: 'titulo', ordenavel: true},
                        {
                            legenda: 'Url',
                            campo: 'url',
                            ordenavel: true,
                            template: (data) => <a href={data.url} target="_blank" rel="noreferrer">{data.url}</a>
                        },
                        {legenda: 'Data de cadastro', campo: 'datacadastro', ordenavel: true},
                        {legenda: '', campo: 'status', ordenavel: false, template: status},
                    ],
                    footer: footer,
                },
                abrirFormulario: aoAbrirFormulario,
                finalizarPesquisa: aoListar,
            }
            setParametrosTela(parametros);
        }).catch((erro) => {
            throw {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
        });
    }, []);

    const botoesAcaoTela = () => {
        return (<React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text"
                    onClick={(e) => setExibirTela(false)}/>
            {item.id > 0 && permissoesTela.excluir &&
                <Button label="Excluir" icon="pi pi-trash" className="p-button-text"
                        onClick={(e) => aoExcluirItem(item.id)}/>}
            {((item.id > 0 && permissoesTela.alterar) || permissoesTela.cadastrar) &&
                <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={aoSalvar}/>}
        </React.Fragment>);
    };

    return (
        <>
            <Tabela parametros={parametrosTela} listar={listar}>
                <Toast ref={aviso}></Toast>
                <Dialog className='fluid' header={tituloTela} style={{width: '50%'}} footer={botoesAcaoTela}
                        modal={true} visible={exibeTela} onHide={(e) => setExibirTela(false)}>
                    <div className="grid formgrid">
                        <div className="field col-12 mt-3">
                            <label htmlFor="titulo">Ativo? </label><br/>
                            <InputSwitch checked={item.status == 1 ? true : false} onChange={(e) => setItem({...item, status: e.value ? 1 : 2})}/>
                        </div>
                        <div className="field col-12">
                            <label htmlFor="titulo">Título<Obrigatorio>*</Obrigatorio> </label><br/>
                            <InputText className="w-full" autoComplete="none" id="titulo" maxLength={255}
                                       value={item.titulo} onChange={(e) => aoDigitar(e, 'titulo')} required/>
                        </div>
                        <div className="field col-12">
                            <label htmlFor="url">Url<Obrigatorio>*</Obrigatorio> </label><br/>
                            <InputText className="w-full" autoComplete="none" id="url" maxLength={255} value={item.url}
                                       onChange={(e) => aoDigitar(e, 'url')}/>
                        </div>
                        <div className="field col-12">
                            <label htmlFor="linkexterno">Link externo </label><br/>
                            <InputText className="w-full" autoComplete="none" id="linkexterno" maxLength={255} value={item.linkexterno}
                                       onChange={(e) => aoDigitar(e, 'linkexterno')}/>
                        </div>
                    </div>
                </Dialog>
            </Tabela>
        </>
    );
}

export default LinkYoutube;
