import React, {useEffect, useRef, useState} from "react";
import {Toast}                              from "primereact/toast";
import {InputText}                          from "primereact/inputtext";
import {Button}                             from "primereact/button";
import {confirmDialog}                      from "primereact/confirmdialog";
import {Dialog}                             from "primereact/dialog";

import api                from "../../services/api";
import {Obrigatorio}      from "../../styles/global";
import Tabela             from "../../components/tabela";
import {parametrosPadrao} from "../../configs/constantes";
import debug              from "../../utils/debug";

const Funcionalidade = () => {
    const classe = 'Funcionalidade';
    const endpointAPI = 'configuracao/funcionalidade';
    const tabela = 'funcionalidades'
    const inicial = {id: 0, nome: '', titulo: ''};
    const aviso = useRef(null);
    const [item, setItem] = useState(inicial);
    const [tituloTela, setTituloTela] = useState('');
    const [exibeTela, setExibirTela] = useState(false);
    const [listar, setListar] = useState(false);
    const [permissoesTela, setPermissoesTela] = useState(null);
    const [parametrosTela, setParametrosTela] = useState(parametrosPadrao);

    const aoAbrirFormulario = (registro) => {
        if (registro.id) {
            setItem({...registro});
            setTituloTela(`Alterar ${classe}`);
        } else {
            setItem(inicial);
            setTituloTela(`Cadastro de ${classe}`);
        }
        setExibirTela(true);
    };

    const aoListar = () => setListar(false);

    const limparTela = () => {
        setItem(inicial);
        setExibirTela(false);
        setListar(true);
    };

    const excluir = async (id) => {
        await new api().requisitar('DELETE', `${endpointAPI}/${id}`)
                       .then((resposta) => {
                           aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
                           limparTela();
                       })
                       .catch((erro) => {
                           debug(erro);
                           aviso.current.show({severity: 'error', summary: 'Erro', detail: (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado', life: 3000});
                       });
    };

    const aoExcluirItem = (id) => {
        confirmDialog({
                          message    : `Confirma exclusão da ${classe}?`,
                          header     : 'Atenção',
                          icon       : 'pi pi-exclamation-triangle',
                          acceptLabel: 'Sim',
                          rejectLabel: 'Não',
                          accept     : () => {
                              excluir(id);
                          },
                      });
    };

    const aoSalvar = async () => {
        try {
            if (!item.nome) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o nome!'};
            }

            if (!item.titulo) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o título!'};
            }

            await new api().requisitar((item.id > 0) ? 'put' : 'post', (item.id > 0 ? `${endpointAPI}/${item.id ?? ''}` : endpointAPI), item)
                           .then((resposta) => {
                               aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
                               limparTela();
                           })
                           .catch((erro) => {
                               throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
                           });

        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    };

    const aoDigitar = (e, propriedade) => {
        const texto = (e.target && e.target.value) || '';
        let _item = {...item};
        _item[`${propriedade}`] = texto;
        setItem(_item);
    };

    useEffect(() => {
        new api().requisitar('get', `configuracao/tela/funcionalidade/permissoes`)
                 .then((resposta) => {
                     setPermissoesTela(resposta);
                     const parametros = {
                         pesquisarTabela  : true,
                         artigo           : 'a',
                         nomeClasse       : classe,
                         tabela           : tabela,
                         caminhoBreadcrumb: [{label: 'Configurações'}, {label: 'Funcionalidades'}],
                         endpointApi      : endpointAPI,
                         tabelaDados      : {
                             reordenar              : true,
                             multiplaSelecao        : true,
                             botaoAdicionar         : resposta.cadastrar ?? false,
                             botaoExcluir           : resposta.excluir ?? false,
                             botaoEditarRegistro    : resposta.alterar ?? false,
                             botaoExcluirRegistro   : resposta.excluir ?? false,
                             botaoAuditoriaRegistro : resposta.auditoria ?? false,
                             botaoVisualizarRegistro: resposta.visualizar ?? false,
                             colunas                : [{legenda: 'Título', campo: 'titulo', ordenavel: true}, {legenda: 'Nome', campo: 'nome', ordenavel: true}]
                         },
                         abrirFormulario  : aoAbrirFormulario,
                         finalizarPesquisa: aoListar
                     }
                     setParametrosTela(parametros);
                 })
                 .catch((erro) => {
                     throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
                 });

    }, []);

    const botoesAcaoTela = () => {
        return (<React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={(e) => setExibirTela(false)}/>
            {item.id > 0 && permissoesTela.excluir && <Button label="Excluir" icon="pi pi-trash" className="p-button-text" onClick={(e) => aoExcluirItem(item.id)}/>}
            {((item.id > 0 && permissoesTela.alterar) || permissoesTela.cadastrar) && <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={aoSalvar}/>}
        </React.Fragment>);
    };

    return (
        <Tabela parametros={parametrosTela} listar={listar}>
            <Toast ref={aviso}></Toast>
            <Dialog className='fluid' header={tituloTela} style={{minWidth: '450px'}} footer={botoesAcaoTela} modal={true} visible={exibeTela} onHide={(e) => setExibirTela(false)}>
                <div className="grid formgrid">
                    <div className="field col mt-3">
                        <label htmlFor="nome">Nome<Obrigatorio>*</Obrigatorio> </label><br/>
                        <InputText className="w-full" autoComplete="none" id="nome" maxLength={50} value={item.nome} onChange={(e) => aoDigitar(e, 'nome')} required/>
                        <label htmlFor="titulo">Título<Obrigatorio>*</Obrigatorio> </label><br/>
                        <InputText className="w-full" autoComplete="none" id="nome" maxLength={50} value={item.titulo} onChange={(e) => aoDigitar(e, 'titulo')} required/>
                    </div>
                </div>
            </Dialog>
        </Tabela>
    );
}


export default Funcionalidade;
