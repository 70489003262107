import React, { memo } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { Panel } from "primereact/panel";
import { SplitButton } from 'primereact/splitbutton';
import { InputSwitch } from 'primereact/inputswitch';
import { ProgressSpinner } from 'primereact/progressspinner';

const tplCabecalho = (configurar, fnAoDigitar, fnAoRedimensionar, opcoes) => {
  const tamanhos = [
    { icon: 'pi pi-arrows-h', label: 'Minúsculo', command: () => fnAoRedimensionar(2, opcoes.props.indicador.nome) },
    { icon: 'pi pi-arrows-h', label: 'Pequeno', command: () => fnAoRedimensionar(3, opcoes.props.indicador.nome) },
    { icon: 'pi pi-arrows-h', label: 'Médio', command: () => fnAoRedimensionar(4, opcoes.props.indicador.nome) },
    { icon: 'pi pi-arrows-h', label: 'Grande', command: () => fnAoRedimensionar(6, opcoes.props.indicador.nome) },
    { icon: 'pi pi-arrows-h', label: 'Gigante', command: () => fnAoRedimensionar(12, opcoes.props.indicador.nome) },
  ];

  return (
    <div className={`${opcoes.className}`}>
      <div className={`${opcoes.titleClassName} text-left`}
        style={{ fontSize: '0.9rem' }}>{opcoes.props.title}</div>
      {!configurar && !opcoes.props.indicador.atualizando &&
        <div className="text-right" title={'Última atualização'} style={{ fontSize: '0.7rem' }}><i>
          {(opcoes?.props?.indicador?.atualizado ? (new Date(opcoes.props.indicador.atualizado)?.toLocaleTimeString('pt-BR')) : '')}
        </i></div>
      }
      {configurar &&
        <div className="text-right">
          <SplitButton icon="pi pi-arrows-h" model={tamanhos} raised text title={'Configurar tamanho'} />
          <InputSwitch
            checked={opcoes.props.indicador.visivel}
            onChange={(e) => fnAoDigitar(e, opcoes.props.indicador.nome)}
          />
        </div>
      }
      {!configurar && opcoes.props.indicador.atualizando &&
        <div className="text-right">
          <ProgressSpinner style={{ width: 20, height: 20 }} />
        </div>
      }
    </div>
  );
}

const SortableItem = memo((props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: props.configurar ? props.id : false });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition
  };
  return (
    <React.Fragment>
      {(props.indicador.visivel || props.configurar) &&
        <div ref={setNodeRef} style={style} {...attributes} {...listeners} className={`field col-${props.indicador.tamanho}`}>
          <Panel headerTemplate={tplCabecalho.bind(null, props.configurar, props.callbackOnType, props.callbackOnResize)} title={props.indicador.titulo} indicador={props.indicador}>
            
          </Panel>
        </div>
      }
    </React.Fragment>
  );
});

export default SortableItem;