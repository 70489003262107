export default class Texto {
	constructor() {
		this.regras = {
			acrescentar: {
				's':  ['a', 'e', 'i', 'o', 'u', 'ã', 'ãe', 'é'],
				'es': ['r', 'z', 'n', 'ás'],
				'':   ['is', 'us', 'os']
			},
			substituir:  {
				'ais':  'al',
				'eis':  'el',
				'ois':  'ol',
				'uis':  'ul',
				'is':   'il',
				'ns':   'm',
				'eses': 'ês',
				'ões':  'ão'
			},
			excecoes:    {
				'males':    'mal',
				'cônsules': 'cônsul',
				'méis':     'mel',
				'féis':     'fel',
				'cais':     'cal',
				'Leads':    'Lead',
				'Banners':  'Banner',
				'leads':    'lead',
				'banners':  'banner',
			},
			sem_plural:  [
				'não', 'de'
			],
		};
	}

	_plural(palavra) {
		const regex_troca = "^([a-zA-Zà-úÀ-Ú]*)(%s)$";
		let plural = "";

		for (let regra in this.regras) {
			switch (regra) {
				case 'acrescentar':
					for (let adicao in this.regras[regra]) {
						const busca = regex_troca.replace("%s", this.regras[regra][adicao].join("|")), regex = new RegExp(busca, 'i');
						if (regex.exec(palavra) !== null) {
							plural = palavra + adicao;
							break;
						}
					}
					break;
				case 'substituir':
					for (let substituicao in this.regras[regra]) {
						const busca = regex_troca.replace("%s", this.regras[regra][substituicao]), regex = new RegExp(busca, 'i');
						if (regex.exec(palavra) !== null) {
							if (palavra.match(/([áéíóú])/) !== null && regex.exec(palavra)[2] === "il") {
								plural = palavra.replace("il", "eis");
								break;
							} else {
								const busca_sub = new RegExp(regex.exec(palavra)[2] + '$', 'i');
								plural = palavra.replace(busca_sub, substituicao);
								break;
							}
						}
					}
					break;
				case 'excecoes':
					for (let excecao in this.regras[regra]) {
						if (palavra === this.regras[regra][excecao]) {
							plural = excecao;
							break;
						}
					}
					break;
				case 'sem_plural':
					this.regras[regra].forEach(function (r) {
						if (palavra === r) {
							plural = palavra;
						}
					});
					break;
				default:
					this.regras[regra].forEach(function (r) {
						if (palavra === r) {
							plural = palavra;
						}
					});
			}

		}

		return plural !== "" ? plural : palavra;
	}

	plural(texto, pluralizar = true) {
		if (pluralizar) {
			var palavrasPlural = texto.split(' ');

			palavrasPlural.forEach((palavra, i) => palavrasPlural[i] = this._plural(palavra));

			return palavrasPlural.join(' ');
		} else {
			return texto;
		}
	}

}
