import React, {useRef, useState} from "react";
import {Toast}                   from "primereact/toast";
import {Button}                  from "primereact/button";
import {InputText}               from "primereact/inputtext";
import {Dialog}          from "primereact/dialog";
import {useNavigate}             from "react-router-dom";
import axios                     from 'axios';

import {Container, Tela} from "../../styles/styles";
import Logo              from "../../assets/LogoLogin.png";

import * as S from './styles';

const Esquecisenha = () => {
	const inicial = {
		email: '',
	};
	const [item, setItem] = useState(inicial);
	const aviso = useRef(null);
	const [alerta, setAlerta] = useState(false);
	const [mensagem, setMensagem] = useState('');
	const navegacao = useNavigate();
	const legendaTitulo = 'Potencial Imóveis';
	
	const [loading, setLoading] = useState(false);
	

	
	const aoEnviar = async () => {
		try {
			if (!item.email) {
				throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o e-mail!'};
			}
			
			setLoading(true);
			const resp = await axios.post(process.env.REACT_APP_URLAPI + 'seguranca/usuario/esqueceu-senha', item);
			if (resp.data) {
				setLoading(false);
				setMensagem(resp.data.mensagem);
				setAlerta(true);
			} else {
				setLoading(false);
				throw  {tipo: 'error', titulo: 'Falha!!', mensagem: 'Ocorreu um erro não tratado'};
			}
			
		} catch (erro) {
			setLoading(false);
			aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro.response.data, life: 3000});
		}
	};
	
	const aoFechar = () => {
		setAlerta(false);
		navegacao('/');
	}
	
	const aoEnter = (e) => {
		if (e.key === 'Enter') {
			aoEnviar(e);
		}
	};
	
	const aoDigitar = (e, propriedade) => {
		const texto = (e.target && e.target.value) || '';
		let _item = {...item};
		_item[`${propriedade}`] = texto;
		setItem(_item);
	};
	
	const botao = (name) => {
		return (
			<div>
				<Button label="OK" icon="pi pi-check" onClick={aoFechar} autoFocus />
			</div>
		);
	}
	
	return (<Container>
		<Toast ref={aviso}></Toast>
		<Dialog onHide={aoFechar} visible={alerta} footer={botao} header="Confirmação">
			<label>{mensagem}</label>
		</Dialog>
		<Tela>
			<div className="grid">
				<div className="field col-12" style={{textAlign: 'center'}}>
					<div style={{padding: '0 10% 0 10%'}}>
						<a href="https://potencialimoveis.com.br/" target="__blank">
							<S.Logo src={Logo} alt={legendaTitulo} title={legendaTitulo} rel={"noreferrer"}/>
						</a>
					</div>
				</div>
				<div className="field col-12 sm:12">
					<div className="float-label input-icon-left">
						<S.Formulario>
						<InputText placeholder={"Informe seu e-mail"} className={'campo w-full'} autoComplete={'off'} id="login" maxLength={150} value={item.email} onChange={(e) => aoDigitar(e, 'email')} onKeyDown={aoEnter} required disabled={loading} autoFocus/>
						</S.Formulario>
					</div>
				</div>
				<div className="col-12 lg:col-6" style={{textAlign: 'center'}}>
					<S.ButtonVoltar>
						<Button label='Voltar' onClick={(e) => { window.location = '/'; }} className={'botao'} disabled={loading}/>
					</S.ButtonVoltar>
				</div>
				<div className="col-12 lg:col-6" style={{textAlign: 'center'}}>
					<S.ButtonEnviar>
							<Button label='Enviar' onClick={aoEnviar} className={'botao'} disabled={loading}/>
					</S.ButtonEnviar>
				</div>
			</div>
		</Tela>
	</Container>);
}


export default Esquecisenha;
